<template>
  <router-view></router-view>
</template>

<script>
 
export default {
  data() {
    return {
    };
  },
  created: function () {}
  }
</script>
