<template>
  <div class="row">
    <div class="col-md-12 card">
      <div class="card-header">
        <div class="category">View Passengers</div>
      </div>
      <div class="card-body row">
        <div class="col-sm-6">
          <el-select class="select-default" v-model="pagination.perPage" placeholder="Per page" size="small">
            <el-option class="select-default" v-for="item in perPageOptions" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="col-sm-6">
          <div class="pull-right">
            <fg-input class="input-sm" placeholder="Search" v-model="term" addon-right-icon="nc-icon nc-zoom-split">
            </fg-input>
          </div>
        </div>
        <div class="col-sm-12 mt-2">
          <el-table class="table-striped" header-row-class-name="text-primary" :data="passengers" style="width: 100%">

            <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Name">
              <template slot-scope="props">
                {{ props.row.ticket_detail.passenger.full_name }}
              </template>
            </el-table-column>

            <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="CONTACT">
              <template slot-scope="props">
                {{ props.row.ticket_detail.passenger.contact_number }}
              </template>
            </el-table-column>

            <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="BOOKING INFO">
              <template slot-scope="props">
                {{ props.row.booking.route.code }} | {{ props.row.booking.booking_date }} | {{
                props.row.booking.formatted_departure_time }} | {{ props.row.accommodation.code }}
              </template>
            </el-table-column>

            <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="TOTAL FARE">
              <template slot-scope="props">
                {{ money(props.row.ticket_detail.total_fare) }}
              </template>
            </el-table-column>

            <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="STATUS">
              <template slot-scope="props">
                <el-tag type="success">{{ $store.state.tickets_status[props.row.status]}}</el-tag>
                <el-tag type="warning" v-if="props.row.is_refunded">Refunded</el-tag>
                <el-tag type="danger" v-if="props.row.has_been_rescheduled">Rescheduled</el-tag>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">Found {{ pagination.total }} data</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage"
            :total="pagination.total">
          </p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Passenger
} from '@/resources/passenger'

export default {
  data() {
    return {
      passengers: [],
      selected: null,
      perPageOptions: [5, 10, 15],
      pagination: {
        perPage: 15,
        currentPage: 1,
        total: 0
      },
      term: null,
    }
  },
  watch: {
    term: function () {
      if (!this.term || this.term.length > 2) {
        this.getPassengers()
      }
    },
    'pagination.perPage': function () {
      if (this.pagination.currentPage == 1) {
        this.getPassengers()
      } else {
        this.pagination.currentPage = 1
      }
    },
    'pagination.currentPage': function () {
      this.getPassengers()
    }
  },
  created: function () {
    this.getPassengers()
  },
  methods: {

    getPassengers() {
      let params = {
        term: this.term,
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage
      }
      this.$loading = true
      Passenger.get(params)
        .then(result => {
          if (result.data.passengers)
            this.passengers = result.data.passengers
          this.pagination = result.data.meta
        })
        .catch(() => {
          this.$notify({
            type: 'danger',
            title: "Error",
            text: 'Unable to fetch passengers',
          });

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>

<style lang="scss"></style>
