import api from './api'

const API = '/api/client/passengers'

export const Passenger = {
  get: function(params) {
    return api.get(API, { params: params})
  },
}

