import api from '../api'

const API = '/api/client/all-tickets'

export const ClientTicket = {

  get: function(params) {
    return api.get(API, {params: params})
  },
  show: function(id, params) {
    return api.get(API + '/' + id, params)
  },
  create: function(form) {
    return api.create(API, form)
  },
  update: function(form) {
    return api.update(API+'/'+form.id, form)
  },
  delete: function(params) {
    return api.delete(API+'/'+params.id, params)
  }
}
