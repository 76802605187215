<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <h4>Search Tickets</h4>

      </div>
      <div class="col-md-3">
        <div class="card">
          <div class="card-header">
            <form @submit.prevent="submit">
              <el-input placeholder="Search by group code" v-model="code" class="input-with-select" size="mini">
                <el-button slot="append" icon="el-icon-search" size="mini" type="button" @click="submit"></el-button>
              </el-input>


              <div class="form-group" v-if="group_ticket">
                <hr>
                <label>Filter by code or name</label>
                <el-input placeholder="Search tickets" v-model="term" class="input-with-select" size="mini">
                  <el-button slot="append" icon="el-icon-search" size="mini" type="button" @click="submit"></el-button>
                </el-input>
              </div>
            </form>
          </div>
          <div class="card-body">
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-3" v-for="(ticket, i) in tickets" :key="i">
            <ticket-card :ticket="ticket" @close="getTickets"></ticket-card>
          </div>
        </div>
      </div>
      <div class="col-sm-6 pagination-info">
        <p class="category">Found {{ pagination.total }} tickets</p>
      </div>
      <div class="col-sm-6" v-if="pagination.total">
        <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage"
          :total="pagination.total">
        </p-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Ticket
} from '@/resources/ticket'
import TicketCard from './TicketCard.vue'

export default {
  components: { TicketCard },
  data() {
    return {
      code: null,
      group_ticket: null,
      tickets: [],
      pagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
      term: null
    }
  },
  watch: {
    'pagination.perPage': function () {
      if (this.pagination.currentPage == 1) {
        this.getTickets()
      } else {
        this.pagination.currentPage = 1
      }
    },
    'pagination.currentPage': function () {
      this.getTickets()
    },
    'term': function () {
      if (!this.term || this.term.length > 2) this.getTickets()
    }
  },
  methods: {
    submit() {
      if (!this.code) return
      this.getTickets()
    },
    getTickets() {
      let params = {
        group_ticket_code: this.code,
        page: this.pagination.currentPage,
        per_page: 10,
      }
      if (this.group_ticket) params.term = this.term

      this.$loading = true
      Ticket.get(params)
        .then(result => {
          if (result.data.tickets)
            this.tickets = result.data.tickets
          this.user_id = result.data.user_id
          this.pagination = result.data.meta
          this.group_ticket = result.data.group_ticket
        })
        .catch(() => {
          this.tickets = []
          this.group_ticket = null
        }).finally(() => {
          this.$loading = false
        })
    },
  }
}
</script>

<style lang="scss"></style>
